import * as React from "react"
import PageCiudad from "./subtemplates/subtemplate_ciudad"

// export const query = graphql`
//   query($pwid: Int!, $lang: String!) {
//     sitePage(context: { pwid: { eq: $pwid }, lang: {eq: $lang} }) {
//       context {
//         seo_image {
//           url
//         }
//         seo_no_indexable
//         seo_header_no_h1
//         donde_comprar_texto1
//         donde_comprar_texto2
//         donde_comprar_ciudad
//         donde_comprar_pais
//         donde_comprar_slug
//         content {
//           type
//           data {
//             title
//             url
//             title_tipo
//             texto_corto1
//             texto_corto2
//             texto_sin_formato1
//             texto_con_formato1
//             descarga
//             checkbox1
//             checkbox2
//             checkbox3
//             numero1
//             numero2
//             styles
//             htmltag
//             blocks {
//               type
//               title
//               url
//               texto_corto1
//               texto_con_formato1
//               texto_sin_formato1
//               image_mobile {
//                 aspectratio
//                 base64
//                 description
//                 height
//                 url
//                 url220
//                 url440
//                 url880
//                 url980
//                 url1760
//                 url2000
//                 url220webp
//                 url440webp
//                 url880webp
//                 url980webp
//                 url1760webp
//                 url2000webp
//                 width
//               }
//               image {
//                 aspectratio
//                 base64
//                 description
//                 height
//                 url
//                 url220
//                 url440
//                 url880
//                 url980
//                 url1760
//                 url2000
//                 url220webp
//                 url440webp
//                 url880webp
//                 url980webp
//                 url1760webp
//                 url2000webp
//                 width
//               }
//             }
//           }
//         }
//         seo_title
//         seo_description
        
//         title
//         parent_page {
//           title
//           ruta
//         }
//       }
//     }

//     processwire {
//       config {
//         es {
//           ciudad_parte1 {
//             type
//             data {
//               title
//               title_tipo
//               texto_corto1
//               texto_corto2
//               texto_sin_formato1
//               texto_con_formato1
//               checkbox1
//               numero1
//               numero2
//               blocks {
//                 image_mobile {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//                 image {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//               }
//             }
//           }
//           ciudad_parte2 {
//             type
//             data {
//               title
//               title_tipo
//               texto_corto1
//               texto_corto2
//               texto_sin_formato1
//               texto_con_formato1
//               checkbox1
//               numero1
//               numero2
//               blocks {
//                 title
//                 url
//                 texto_corto1
//                 texto_con_formato1
//                 image_mobile {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//                 image {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//               }
//             }
//           }
//         }
//         en{
//           ciudad_parte1 {
//             type
//             data {
//               title
//               title_tipo
//               texto_corto1
//               texto_corto2
//               texto_sin_formato1
//               texto_con_formato1
//               checkbox1
//               numero1
//               numero2
//               blocks {
//                 image_mobile {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//                 image {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//               }
//             }
//           }
//           ciudad_parte2 {
//             type
//             data {
//               title
//               title_tipo
//               texto_corto1
//               texto_corto2
//               texto_sin_formato1
//               texto_con_formato1
//               checkbox1
//               numero1
//               numero2
//               blocks {
//                 title
//                 url
//                 texto_corto1
//                 texto_con_formato1
//                 image_mobile {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//                 image {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//               }
//             }
//           }
//         }
//         fr{
//           ciudad_parte1 {
//             type
//             data {
//               title
//               title_tipo
//               texto_corto1
//               texto_corto2
//               texto_sin_formato1
//               texto_con_formato1
//               checkbox1
//               numero1
//               numero2
//               blocks {
//                 image_mobile {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//                 image {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//               }
//             }
//           }
//           ciudad_parte2 {
//             type
//             data {
//               title
//               title_tipo
//               texto_corto1
//               texto_corto2
//               texto_sin_formato1
//               texto_con_formato1
//               checkbox1
//               numero1
//               numero2
//               blocks {
//                 title
//                 url
//                 texto_corto1
//                 texto_con_formato1
//                 image_mobile {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//                 image {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//               }
//             }
//           }
//         }
//         pt{
//           ciudad_parte1 {
//             type
//             data {
//               title
//               title_tipo
//               texto_corto1
//               texto_corto2
//               texto_sin_formato1
//               texto_con_formato1
//               checkbox1
//               numero1
//               numero2
//               blocks {
//                 image_mobile {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//                 image {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//               }
//             }
//           }
//           ciudad_parte2 {
//             type
//             data {
//               title
//               title_tipo
//               texto_corto1
//               texto_corto2
//               texto_sin_formato1
//               texto_con_formato1
//               checkbox1
//               numero1
//               numero2
//               blocks {
//                 title
//                 url
//                 texto_corto1
//                 texto_con_formato1
//                 image_mobile {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//                 image {
//                   aspectratio
//                   base64
//                   description
//                   height
//                   url
//                   url220
//                   url440
//                   url880
//                   url980
//                   url1760
//                   url2000
//                   url220webp
//                   url440webp
//                   url880webp
//                   url980webp
//                   url1760webp
//                   url2000webp
//                   width
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

const PageCiudadTemplate = props => <PageCiudad {...props} />
export default PageCiudadTemplate
