import * as React from "react"
import tw from "twin.macro"

import Content from "../../components/pwcomps/content"
import DondeComprarTexto from "../../components/mini/dondeComprarTexto"
import { TransitionState } from "../../helpers/DummyGatsbyPluginTransitionLink"
import { Spring, animated as a } from "@react-spring/web"
import BreadCrumb from "../../components/breadcrumb"
import Footer from "../../components/footer"
import Seo from "../../components/seo"
import FormularioDistribuidor from "../../components/mini/formularioDistribuidor"

const PageCiudad = props => {
  const { pageContext } = props
const { lang, ciudad_parte1, ciudad_parte2 } = pageContext;
  return (
    <>
      <Seo
        pageContext={pageContext}
      />
      <TransitionState>
        {({ transitionStatus, exit, entry }) => {
          const isMounting = ["entering", "entered"].includes(transitionStatus)
          const isExiting = transitionStatus === "exiting"
          return (
            <>
              <div css={tw`px-5 md:px-9 overflow-x-visible`}>
                <div
                  css={tw`max-w-screen-xl my-0 mx-auto px-0 font-obbody overflow-x-visible`}
                >
                  <article>
                    <div css={tw`relative `}>
                      <BreadCrumb
                        isMounting={isMounting}
                        pageContext={pageContext}
                      />
                      <Spring
                        to={{
                          transform: `translateY(${
                            isMounting ? "0rem" : isExiting ? "-2rem" : "10rem"
                          })`,
                          opacity: isMounting ? 1 : 0,
                        }}
                      >
                        {styles => (
                          <a.div style={styles}>
                            <DondeComprarTexto
                              posicion="encima"
                              texto={pageContext.donde_comprar_texto2}
                            />
                            <Content
                              contents={ciudad_parte1}
                              lang={pageContext.lang}
                              isMounting={isMounting}
                              isExiting={isExiting}
                              pageRuta={pageContext.ruta}
                            />
                            {/* <Content
                        contents={
                          pageContext.content
                        }
                        isMounting={isMounting}
                        isExiting={isExiting}
                      /> */}
                            <DondeComprarTexto
                              posicion="debajo"
                              texto={pageContext.donde_comprar_texto1}
                            />
                            <hr tw="h-px bg-marron1 border-0 mt-4 mb-6" />
                            <FormularioDistribuidor />
                            <Content
                              contents={ciudad_parte2}
                              lang={pageContext.lang}
                              isMounting={isMounting}
                              isExiting={isExiting}
                            />
                          </a.div>
                        )}
                      </Spring>
                    </div>
                  </article>
                </div>
              </div>
              <Footer isMounting={isMounting} lang={lang} langSettings={props.pageContext.langSettings} />
            </>
          )
        }}
      </TransitionState>
    </>
  )
}

export default PageCiudad
