import React, { useRef, useEffect, useState } from 'react'
import tw, {css} from 'twin.macro'
import htmlFilters from "../../helpers/htmlFilters";

const DondeComprarTexto = (props) => {

    const { texto, posicion } = props


    return (
        <div
        css={[posicion === 'encima' ? cssTextoEncima : cssTextoDebajo]}
         >{htmlFilters(texto)}</div>
    )
}

export default DondeComprarTexto

const cssTextoEncima = css`
    &{
        ${tw`py-4 text-obmed md:text-obmed2 text-gris1  dark:text-gris3`}
    }
    @media (min-width: 1024px) {
        max-width: 66%;
    }
    h2, h3, h4, h5, h6{
        ${tw`pb-4`}
        ${tw`text-obmed md:text-obmed2`}
        ${tw`uppercase`}
        ${tw`font-bold`}
        line-height: 1.8em;
    }
    p{
        ${tw`pb-4`}
        line-height: 1.8em;

    }
    a{
        ${tw`underline`}
        ${tw`text-marron1`}
    }
    strong, b{
        ${tw`font-bold`}
    }
`

const cssTextoDebajo = css`
    &{
        ${tw`py-4 text-obsmall text-gris1 dark:text-gris3`}
    }
    @media (min-width: 768px) {
        column-count: 2;
        column-gap: 1rem;
    }
    @media (min-width: 1024px) {
        column-count: 3;
    }
    h2, h3, h4, h5, h6{
        ${tw`pb-4`}
        ${tw`text-obsmall md:text-sm`}
        ${tw`uppercase`}
        ${tw`font-bold`}
        line-height: 1.8em;
    }
    p{
        ${tw`pb-4`}
        line-height: 1.8em;

    }
    a{
        ${tw`underline`}
        ${tw`text-marron1`}
    }
    strong, b{
        ${tw`font-bold`}
    }
`